import { StyleSheet } from 'react-native';

const createStyles = (colorScheme) => StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colorScheme === 'dark' ? 'rgba(0,0,0, 1)' : '#fff',
  },
  component: {
    backgroundColor: colorScheme === 'dark' ? 'black' : 'white',
    borderWidth: 1,
    borderColor: colorScheme === 'dark' ? 'black' : 'white',
    borderRadius: 18,
    width: '100%',
    marginBottom: 8,
    marginTop: '4%',
    padding: 20
  },
  closeButton: {
    fontSize: 20,
    fontWeight: 'bold',
    borderWidth: 1,
    borderColor: colorScheme === 'dark' ? 'black' : '',
    color: 'black',
    padding: 20,
    marginRight: 5,
    borderRadius: 12,
    backgroundColor: 'orange',
  },
  saveButton: {
    fontSize: 20,
    fontWeight: '900',
    borderWidth: 1,
    borderColor: 'black',
    color: 'black',
    padding: 20,
    marginRight: 5,
    borderRadius: 12,
    backgroundColor: '#75FF33',
    shadowColor: 'green',
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: 0.23,
    shadowRadius: 68,
  },
  buttonsRow: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
  },
  company: {
    fontSize: 20,
    fontWeight: '300',
    color: colorScheme === 'dark' ? 'lightgrey' : 'black',
    backgroundColor: 'rgba(255, 255, 255, 0.012)',
    width: '100%',
    borderWidth: 1,
    marginLeft: 0,
    letterSpacing: .76,
    outline: 'none',
    marginRight: 0,
    borderRadius: 11,
    textAlign: 'center',
    borderColor: colorScheme === 'dark' ? 'black' : 'white',
    padding: 8,
    shadowColor: 'rgba(150, 58, 100, .043)',
    shadowOffset: { width: 0, height: 20 },
    shadowOpacity: 1, 
    shadowRadius: 33, 
    marginTop: '5%',
    marginBottom: '3%',
    zIndex: 1,
  },
  errorText: {
    color: 'red',
    textAlign: 'center'
  },
  scrollContainer: {
    overflow: 'scroll',
    height: '100%',
  },
  buttonNew: {                   
    padding: 10,
    borderRadius: 12,
    width: 200,
    height: 70,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'orange',
    shadowColor: 'rgba(150, 58, 100, .17)',
    shadowOffset: { width: 0, height: 30 },
    shadowOpacity: 1, 
    shadowRadius: 31, 
  },
  disclaimer: {
    fontSize: 14,
    fontWeight: '100',
    color: 'grey',
    textAlign: 'center',
    marginTop: -10,
  },
  buttonNewClose: {                   
    borderRadius: 12,
    marginRight: 8,
    right: '25%',
    top: '50%',
  },
  checkboxLabel: {
    color: 'grey',
  },
  checkboxContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '7%',
    gap: '10px',
  }
});

export default createStyles;