import {
  SET_LOGIN_USERNAME,
  SET_LOGIN_PASSWORD,
  SET_REGISTRATION_EMAIL,
  SET_REGISTRATION_USERNAME,
  SET_REGISTRATION_PASSWORD,
  SET_REGISTRATION_CODE,
  SET_IS_CODE_SENT,
  SET_MODAL_LOGIN_VISIBLE,
  SET_MODAL_REGISTER_VISIBLE,
  SET_MODAL_FORGOT_PASSWORD_VISIBLE,
  SET_MODAL_ADD_CARD_VISIBLE,
  SET_RESET_PASSWORD_PASSWORD,
  SET_RESET_PASSWORD_CONFIRM_PASSWORD,
  SET_MODAL_NAME,
  SET_MODAL_PHONE,
  SET_MODAL_COMPANY,
  SET_MODAL_POSITION,
  SET_MODAL_ADDRESS,
  SET_MODAL_EMAIL,
  SET_MODAL_WEBSITE,
  SET_MODAL_LOGO,
  SET_IS_LOADING,
  SET_CARDS,
  SET_USERNAME_VALID,
  SET_PASSWORD_VALID,
  SET_EMAIL_VALID,
  SET_CODE_VALID,
  SET_GENERAL_ERROR,
  SET_IS_FORM_SUBMITTED,
  SET_SEARCH_QUERY,
  SET_SELECTED_ITEM,
  SET_SHOW_QR_CODE,
  SET_SHOW_QR_MODAL,
  SET_REFRESHING,
} from './actions';

const initialState = {
  loginUsername: '',
  loginPassword: '',
  registrationEmail: '',
  registrationUsername: '',
  registrationPassword: '',
  registrationCode: '',
  isCodeSent: false,
  modalLoginVisible: false,
  modalRegisterVisible: false,
  modalForgotPasswordVisible: false,
  modalAddCardVisible: false,
  resetPasswordPassword: '',
  resetPasswordConfirmPassword: '',
  modalName: '',
  modalPhone: '',
  modalCompany: '',
  modalPosition: '',
  modalAddress: '',
  modalEmail: '',
  modalWebsite: '',
  modalLogo: '',
  isLoading: false,
  cards: [],
  selectedCard: null,
  modalVisible: false,
  searchQuery: '',
  selectedItem: null,
  showQrCode: false,
  showQrModal: false,
  refreshing: false,
  isUsernameValid: true,
  isPasswordValid: true,
  isEmailValid: true,
  isCodeValid: true,
  generalError: '',
  isFormSubmitted: false,
};

function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOGIN_USERNAME:
      return { ...state, loginUsername: action.payload };
    case SET_LOGIN_PASSWORD:
      return { ...state, loginPassword: action.payload };
    case SET_REGISTRATION_EMAIL:
      return { ...state, registrationEmail: action.payload };
    case SET_REGISTRATION_USERNAME:
      return { ...state, registrationUsername: action.payload };
    case SET_REGISTRATION_PASSWORD:
      return { ...state, registrationPassword: action.payload };
    case SET_REGISTRATION_CODE:
      return { ...state, registrationCode: action.payload };
    case SET_IS_CODE_SENT:
      return { ...state, isCodeSent: action.payload };
    case SET_MODAL_LOGIN_VISIBLE:
      return { ...state, modalLoginVisible: action.payload };
    case SET_MODAL_REGISTER_VISIBLE:
      return { ...state, modalRegisterVisible: action.payload };
    case SET_MODAL_FORGOT_PASSWORD_VISIBLE:
      return { ...state, modalForgotPasswordVisible: action.payload };
    case SET_MODAL_ADD_CARD_VISIBLE:
      return { ...state, modalAddCardVisible: action.payload };
    case SET_RESET_PASSWORD_PASSWORD:
      return { ...state, resetPasswordPassword: action.payload };
    case SET_RESET_PASSWORD_CONFIRM_PASSWORD:
      return { ...state, resetPasswordConfirmPassword: action.payload };
    case SET_MODAL_NAME:
      return { ...state, modalName: action.payload };
    case SET_MODAL_PHONE:
      return { ...state, modalPhone: action.payload };
    case SET_MODAL_COMPANY:
      return { ...state, modalCompany: action.payload };
    case SET_MODAL_POSITION:
      return { ...state, modalPosition: action.payload };
    case SET_MODAL_ADDRESS:
      return { ...state, modalAddress: action.payload };
    case SET_MODAL_EMAIL:
      return { ...state, modalEmail: action.payload };
    case SET_MODAL_WEBSITE:
      return { ...state, modalWebsite: action.payload };
    case SET_MODAL_LOGO:
      return { ...state, modalLogo: action.payload };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_CARDS:
      return { ...state, cards: action.payload || []};
    case SET_USERNAME_VALID:
      return { ...state, isUsernameValid: action.payload };
    case SET_PASSWORD_VALID:
      return { ...state, isPasswordValid: action.payload };
    case SET_EMAIL_VALID:
      return { ...state, isEmailValid: action.payload };
    case SET_CODE_VALID:
      return { ...state, isCodeValid: action.payload };
    case SET_GENERAL_ERROR:
      return { ...state, generalError: action.payload };
    case SET_IS_FORM_SUBMITTED:
      return { ...state, isFormSubmitted: action.payload };
    case SET_SEARCH_QUERY:
      return { ...state, searchQuery: action.payload };
    case SET_SELECTED_ITEM:
      return { ...state, selectedItem: action.payload };
    case SET_SHOW_QR_CODE:
      return { ...state, showQrCode: action.payload };
    case SET_SHOW_QR_MODAL:
      return { ...state, showQrModal: action.payload };
    case SET_REFRESHING:
      return { ...state, refreshing: action.payload };
    default:
      return state;
  }
}

export default appReducer;