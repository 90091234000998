import { StyleSheet } from 'react-native';

const createStyles = (colorScheme) => StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colorScheme === 'light' ? 'white' : 'black',
    },
    logo: {
        fontSize: 88,
        marginBottom: 5,
        color: colorScheme === 'light' ? 'black' : 'rgba(233, 222, 255, .64)',
        fontWeight: '100',
    },
    input: {
        height: 60,
        width: '80%',
        borderColor: colorScheme === 'light' ? 'white' : 'rgba(70, 61, 103, 0.3)',
        borderWidth: 1,
        fontSize: 18,
        borderRadius: 12,
        outline: 'none',
        marginBottom: 20,
        paddingLeft: 10,
        color: colorScheme === 'light' ? 'black' : 'lightgrey',
    },
    registerText: {
        fontSize: 16,
        marginBottom: 20,
        color: colorScheme === 'light' ? 'darkgrey' : 'darkgrey',
        textDecorationLine: 'underline',
    },
    button: {
        backgroundColor: colorScheme === 'light' ? '#009de0' : '#009de0',
        padding: 15,
        width: '80%',
        borderRadius: 7,
        borderWidth: 1,
        borderColor: colorScheme === 'light' ? 'white' : 'black',
    },
    buttonText: {
        color: colorScheme === 'light' ? 'rgba(0,0,0,0.8)' : '#1f2123',
        fontSize: 22,
        textAlign: 'center',
        fontWeight: '600',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colorScheme === 'light' ? 'white' : 'black',
    },
    errorText: {
        color: 'red',
        fontSize: 16,
        marginBottom: 20,
        textAlign: 'center',
    },
    disabledButton: {
        backgroundColor: 'rgba(0,0,0,0)',
    },
});
  
export default createStyles;