import { StyleSheet } from 'react-native';

const createStyles = (colorScheme) => StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colorScheme === 'light' ? 'white' : 'black',
    width: '90%'
  },
  logo: {
    fontSize: 88,
    marginBottom: 5,
    color: colorScheme === 'light' ? 'black' : '#b8ced4',
    fontWeight: '100',
  },
  title: {
    color: colorScheme === 'light' ? 'black' : 'lightgrey',
    marginBottom: 10,
    fontSize: 22,
    fontWeight: 'bold',
  },
  input: {
    height: 60,
    width: '100%',
    outline: 'none',
    borderColor: colorScheme === 'light' ? 'white' : 'rgba(70, 61, 103, 0.3)',
    borderWidth: 1,
    fontSize: 22,
    borderRadius: 12,
    // letterSpacing: .3,
    marginBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    color: colorScheme === 'light' ? 'black' : 'lightgrey',
  },
  button: {
    backgroundColor: colorScheme === 'light' ? 'white' : 'orange',
    padding: 20,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colorScheme === 'light' ? 'white' : 'black',
    shadowColor: colorScheme === 'light' ? 'lightgrey' : 'black',
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: colorScheme === 'light' ? 0.77 : 0.77,
    shadowRadius: colorScheme === 'light' ? 68 : 68,
  },
  buttonText: {
    color: colorScheme === 'light' ? 'rgba(0,0,0,0.8)' : 'black',
    fontSize: 22,
    textAlign: 'center',
    fontWeight: '600',
  },
  registerText: {
    fontSize: 16,
    marginBottom: 20,
    color: colorScheme === 'light' ? 'darkgrey' : 'darkgrey',
    textDecorationLine: 'underline',
  },
  errorText: {
    color: 'red',
    fontSize: 16,
    marginBottom: 20,
    textAlign: 'center',
  },
  lightModeText: {
    color: 'black',
  },
  lightModeInput: {
    color: 'black',
    borderColor: 'white',
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: 0.07,
    shadowRadius: 68,
  },
  lightModeButton: {
    backgroundColor: 'white',
    borderColor: 'white',
    borderWidth: 1,
    shadowColor: 'lightgrey',
    color: 'black',
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: 0.77,
    shadowRadius: 68,
  },
  lightButtonText: {
    color: 'rgba(0,0,0,0.8)',
    fontSize: 22,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

export default createStyles;