import { StyleSheet } from 'react-native';

const createStyles = (colorScheme) => StyleSheet.create({
    component: {
        fontSize: 20,
        fontWeight: '300',
        color: colorScheme === 'dark' ? 'lightgrey' : 'lightgrey',
        backgroundColor: 'rgba(255, 255, 255, 0.012)',
        width: '100%',
        borderWidth: 1,
        marginLeft: 0,
        letterSpacing: .76,
        outline: 'none',
        marginRight: 0,
        borderRadius: 11,
        textAlign: 'center',
        borderColor: colorScheme === 'dark' ? 'black' : 'white',
        padding: 8,
        shadowColor: 'rgba(150, 58, 100, .043)',
        shadowOffset: { width: 0, height: 20 },
        shadowOpacity: 1, 
        shadowRadius: 33, 
        marginTop: '5%',
        marginBottom: '3%',
      },
});

export default createStyles;