import { StyleSheet } from 'react-native';

const createStyles = (colorScheme) => StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    paddingHorizontal: 10,
    marginTop: '0%',
  },
  greeting: {
    fontSize: 35,
    fontWeight: "600",
    opacity: .76,
    color: colorScheme === 'dark' ? 'rgba(233, 222, 255, .64)' : 'rgba(70, 61, 103, 0.7)',
  },
  plusButton: {   
    padding: 10,
    borderRadius: 12,
    shadowColor: 'rgba(150, 58, 100, .043)',
    shadowOffset: { width: 0, height: 30 },
    shadowOpacity: 1, 
    shadowRadius: 20, 
    borderWidth: 1,
    borderColor: 'rgba(70, 61, 103, 0.3)',
  },
  qrButton: {   
    padding: 10,
    borderRadius: 12,
    shadowColor: 'rgba(150, 58, 100, .043)',
    shadowOffset: { width: 0, height: 30 },
    shadowOpacity: 1, 
    shadowRadius: 20, 
    borderWidth: 1,
    borderColor: 'rgba(70, 61, 103, 0.3)',
  },
  scrollView: {
    paddingBottom: '100%',
    height: '100%',
    backgroundColor: colorScheme === 'dark' ? 'rgba(0,0,0,0.555)' : 'rgba(255,255,255,0.555)',
  },
  qrCodeViewPosition: {
    marginTop:'10%'
  },
  openedCardQr: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15%',
    marginBottom: '5%',
  },
  qrCodeTitle: {
    fontSize: 40,
    fontWeight: "600",
    textAlign: 'left',
    marginLeft: '2%',
    marginTop: '2%',
    color: colorScheme === 'dark' ? 'rgba(233, 222, 255, .56)' : 'rgba(1,14,36, 0.77)',
  },
  qrCode: {
    backgroundColor: 'rgba(70, 61, 103, 0.17)',
    borderRadius: 24,
    padding: 20,
  },
  paragraphText: {
    color: 'grey',
    top: '9%',
    letterSpacing: .56,
  },
  buttonsRowQr: {
    flex: 1,
    flexDirection: 'row',
    marginTop: '17.5%',
    marginBottom: '2.5%',
    justifyContent: 'space-evenly',
    gap: '28px',
  },
  buttonNew: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    borderColor: 'rgba(70, 61, 103, 0.3)',
  },
});

export default createStyles;