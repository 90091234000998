import { useState, useCallback, useEffect, useRef } from 'react';
import {
  SectionList,
  View,
  Modal,
  Text,
  TouchableOpacity,
  Linking,
  useColorScheme,
  ScrollView,
} from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import { useSelector, useDispatch } from 'react-redux';
import { API, Auth } from 'aws-amplify';
import { S3 } from 'aws-sdk';
import { listCards } from '../../../src/graphql/queries';
import { deleteCard } from '../../../src/graphql/mutations';
import createStyles from './CardListStyles';
import CardItem from '../../Molecules/CardItem/CardItem';
import SearchInput from '../../Molecules/SearchInput/SearchInput';
import GreetingsText from '../../Molecules/GreetingsText/GreetingsText';
import EmailIcon from '../../icons/EmailIcon';
import MessageIcon from '../../icons/MessageIcon';
import SafariIcon from '../../icons/SafariIcon';
import CloseIcon from '../../icons/CloseIcon';
import PinIcon from '../../icons/PinIcon';
import QrCodeIcon from '../../icons/QrCodeIcon';
import {
  setCards,
  setModalAddCardVisible,
  setSelectedItem,
  setShowQrModal,
} from '../../../src/redux/actions';
import { BlurView } from 'expo-blur';

export default function CardList() {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [refreshing, setRefreshing] = useState(false);
  const [s3Key, setS3Key] = useState('');
  const [accessKeyId, setAccessKeyId] = useState('');
  const [secretAccessKey, setSecretAccessKey] = useState('');
  const presignedUrlRef = useRef('');
  const selectedItem = useSelector(state => state.selectedItem);
  const showQrModal = useSelector(state => state.showQrModal);
  const colorScheme = useColorScheme();
  const styles = createStyles(colorScheme);
  
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser) {
          fetchCards();
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  const fetchCards = async () => {
    try {
      const result = await API.graphql({
        query: listCards,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      const cardsData = result?.data?.listCards?.items;
      dispatch(setCards(cardsData));
    } catch (err) {
      console.error('Error fetching data:', err.message, err.errors);
    }
  };

  const handleDelete = async () => {
    try {
      await API.graphql({
        query: deleteCard,
        variables: {
          input: {
            id: selectedItem.id,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      deleteFileFromS3(s3Key, accessKeyId, secretAccessKey);
      await fetchCards();
      closeModal();
    } catch (error) {
      console.error('Error deleting the card:', error.message, error.errors);
    }
  };

  const handlePress = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const username = currentUser.username;

      const url = `https://me-v-1-912dc2a2d5f4.herokuapp.com/${username}`;
  
      presignedUrlRef.current = url;
      dispatch(setShowQrModal(true));
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  };

  const deleteFileFromS3 = async (s3Key, accessKeyId, secretAccessKey) => {
    const s3 = new S3({
      accessKeyId,
      secretAccessKey,
      region: 'us-east-1',
    });

    const params = {
      Bucket: 'mecards',
      Key: `public/${s3Key}`,
    };

    try {
      await s3.deleteObject(params).promise();
      console.log(`File deleted from S3: ${s3Key}`);
    } catch (error) {
      console.error('Error deleting file from S3:', error);
      throw error;
    }
  };

  const closeModal = useCallback(() => {
    dispatch(setModalAddCardVisible(false));
    dispatch(setSelectedItem(null));
    setUrlGenerated(false);
  }, [dispatch]);

  const openInGoogleMaps = useCallback((address) => {
    const url = `https://yandex.ru/maps/?text=${encodeURIComponent(address)}`;
    Linking.canOpenURL(url)
      .then((supported) => {
        if (supported) {
          return Linking.openURL(url);
        } else {
          console.log(`Yandex Maps не поддерживается на этом устройстве: ${url}`);
        }
      })
      .catch((error) => console.error('Произошла ошибка при открытии Yandex Maps', error));
  }, []);  

  const shareViaEmail = useCallback((email, name, company) => {
    const subject = `Contact`;
    const body = `Name: ${name}\nCompany: ${company}\nEmail: ${email}`;
    const emailUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    Linking.canOpenURL(emailUrl)
      .then((supported) => {
        if (supported) {
          return Linking.openURL(emailUrl);
        } else {
          console.log('Email is not supported on this device.');
        }
      })
      .catch((error) => console.error('An error occurred', error));
  }, []);

  const shareViaSms = useCallback((phone, name, company) => {
    const message = `Name: ${name}\nCompany: ${company}\nPhone: ${phone}`;
    const smsUrl = `sms:?body=${encodeURIComponent(message)}`;
    Linking.canOpenURL(smsUrl)
      .then((supported) => {
        if (supported) {
          return Linking.openURL(smsUrl);
        } else {
          console.log('SMS is not supported on this device.');
        }
      })
      .catch((error) => console.error('An error occurred', error));
  }, []);
  
  const filteredData = useSelector(state => state.cards || []).filter(item => {
    const lowerCaseSearch = searchQuery.toLowerCase();
    return (
      item.name.toLowerCase().includes(lowerCaseSearch) ||
      item.phone.toLowerCase().includes(lowerCaseSearch) ||
      item.company.toLowerCase().includes(lowerCaseSearch) ||
      item.position.toLowerCase().includes(lowerCaseSearch)
    );
  });

  const sortedData = filteredData.sort((a, b) => a.name.localeCompare(b.name));

  const groupedData = sortedData.reduce((acc, item) => {
    const firstLetter = item.name.charAt(0).toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(item);
    return acc;
  }, {});

  const sections = Object.keys(groupedData).map(letter => ({
    title: letter,
    data: groupedData[letter],
  }));

  const handleCardPress = useCallback((item) => {
    dispatch(setSelectedItem(item));
    setS3Key(`html/card_${item.phone}.html`);
    setAccessKeyId('AKIAWMW2DOEFJFXNV7OU');
    setSecretAccessKey('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');
    dispatch(setModalAddCardVisible(true));
  }, [dispatch]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    fetchCards().then(() => setRefreshing(false));
  }, []);

  const generateVCard = () => {
    if (!selectedItem) return '';

    return `BEGIN:VCARD
VERSION:3.0
FN:${selectedItem.name || ''}
ORG:${selectedItem.company || ''}
TITLE:${selectedItem.position || ''}
TEL;TYPE=CELL:${selectedItem.phone || ''}
ADR;TYPE=WORK:;;${selectedItem.address || ''}
END:VCARD`.trim();
  };

  const vCard = generateVCard();

  return (
    <View style={styles.component}>
      <SectionList
        style={styles.sectionList}
        sections={sections}
        showsVerticalScrollIndicator={false}
        refreshing={refreshing}
        onRefresh={onRefresh}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => (
          <TouchableOpacity activeOpacity={0.8} onPress={() => handleCardPress(item)}>
            <CardItem
              name={item.name}
              company={item.company}
              phone={item.phone}
              position={item.position}
              logo={item.logo}
            />
          </TouchableOpacity>
        )}
        renderSectionHeader={({ section: { title } }) => (
          <View style={styles.sectionHeader}>
            <Text style={styles.sectionHeaderText}>{title}</Text>
          </View>
        )}
        ListHeaderComponent={
          <>
            <GreetingsText onCardCreated={fetchCards} />
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </>
        }
        ListFooterComponent={
          <Modal
            visible={selectedItem !== null}
            animationType="slide"
            onRequestClose={closeModal}
          >
            <View style={styles.bgCardOpened}>
              <ScrollView showsVerticalScrollIndicator={false} style={styles.scrollView}>
                <View style={styles.openedCard}>
                  <View style={styles.cardOpened}>
                    <CardItem
                      name={selectedItem?.name}
                      phone={selectedItem?.phone}
                      company={selectedItem?.company}
                      position={selectedItem?.position}
                      logo={selectedItem?.logo}
                    />
                  </View>
                  {selectedItem?.email && 
                  <View style={styles.infoRow}>
                    <EmailIcon width={18} height={18} color="rgba(70, 61, 103, 0.7)" />
                    <Text style={styles.additionalInfo}> {selectedItem?.email}</Text>
                  </View>}
                  {selectedItem?.website && 
                  <View style={styles.infoRow}>
                    <SafariIcon width={18} height={18} color="rgba(70, 61, 103, 0.7)" />
                    <Text style={styles.additionalInfo}> {selectedItem?.website}</Text>
                  </View>}
                  {selectedItem?.address && 
                  <View style={styles.infoRow}>
                    <PinIcon width={18} height={18} color="rgba(70, 61, 103, 0.7)" />
                    <Text style={styles.additionalInfo}> {selectedItem?.address}</Text>
                  </View>
                  }
                  <View style={styles.buttonsRow}>
                    <TouchableOpacity activeOpacity={0.567} style={styles.buttonNew} onPress={closeModal}>
                      <View>
                        <CloseIcon width={48} height={48} color="rgba(70, 61, 103, 0.17)" />
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity activeOpacity={0.567} style={styles.buttonNew} onPress={() => shareViaSms(selectedItem?.phone, selectedItem?.name, selectedItem?.company)}>
                    <View>
                        <MessageIcon width={48} height={48} color={colorScheme === 'dark' ? '#519951' : '#519951'} />
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity activeOpacity={0.567} style={styles.buttonNew} onPress={() => shareViaEmail(selectedItem?.email, selectedItem?.name, selectedItem?.company)}>
                    <View>
                        <EmailIcon width={48} height={48} color={colorScheme === 'dark' ? 'rgb(88, 178, 255)' : 'rgb(88, 178, 255)'} />
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity activeOpacity={0.567} style={styles.buttonNew} onPress={() => openInGoogleMaps(selectedItem?.address)}>
                      <View>
                        <PinIcon width={48} height={48} color={colorScheme === 'dark' ? '#bd3823' : '#bd3823'} />
                      </View>
                    </TouchableOpacity>
                  </View>
                  <TouchableOpacity style={styles.buttonNewShare}activeOpacity={0.7} onPress={handlePress} disabled={!selectedItem?.name}>
                      <QrCodeIcon width={36} height={36} color={colorScheme === 'dark' ? 'black' : 'black'} />
                      <Text style={styles.buttonNewShareText}>Поделиться</Text>                    
                  </TouchableOpacity>
                  <TouchableOpacity style={styles.removeButton} onPress={handleDelete}>
                    <Text style={styles.deleteButton}>🗑️</Text>
                  </TouchableOpacity>
                </View>
              </ScrollView>
              <Modal
                visible={showQrModal}
                animationType="fade"
                transparent={true}
                onRequestClose={() => dispatch(setShowQrModal(false))}
              >
                <BlurView
                  intensity={90}
                  tint={colorScheme === 'dark' ? 'dark' : 'light'}
                  style={styles.blurView}А
                >
                  <ScrollView showsVerticalScrollIndicator={false} style={styles.scrollView}>
                    <View style={styles.qrCodeViewPosition}>
                      <Text style={styles.qrCodeTitle}>Отсканируйте код</Text>
                      <View style={styles.openedCardQr}>
                          <View style={styles.qrCode}>
                            {selectedItem?.name.includes('(private)') ? (
                                <QRCode
                                  backgroundColor={colorScheme === 'dark' ? 'rgba(233, 222, 255, .56)' : 'white'}
                                  value={vCard}
                                  size={200}
                                />
                              ) : (
                                <QRCode
                                  backgroundColor={colorScheme === 'dark' ? 'rgba(233, 222, 255, .56)' : 'white'}
                                  value={presignedUrlRef.current}
                                  size={200}
                                />
                              )}
                          </View>
                        <Text style={styles.paragraphText}>или поделиться с помощью</Text>
                        <View style={styles.buttonsRowQr}>
                          <TouchableOpacity activeOpacity={0.567} style={styles.buttonNew} onPress={() => dispatch(setShowQrModal(false))}>
                            <View>
                              <CloseIcon width={48} height={48} color="rgba(70, 61, 103, 0.17)" />
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity activeOpacity={0.567} style={styles.buttonNew} onPress={() => shareViaSms(selectedItem?.phone)}>
                            <View>
                              <MessageIcon width={48} height={48} color={colorScheme === 'dark' ? '#519951' : '#519951'} />
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity activeOpacity={0.567} style={styles.buttonNew} onPress={() => shareViaEmail(selectedItem?.email)}>
                            <View>
                              <EmailIcon width={48} height={48} color={colorScheme === 'dark' ? 'rgb(88, 178, 255)' : 'rgb(88, 178, 255)'} />
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </ScrollView>
                </BlurView>
              </Modal>
            </View>
          </Modal>
        }
      />
    </View>
  );
}