import { Text, View, TouchableOpacity, Modal, ScrollView, useColorScheme, Linking } from "react-native";
import { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setModalAddCardVisible, setShowQrModal } from '../../../src/redux/actions';
import PlusIcon from '../../icons/PlusIcon';
import QrCodeIcon from '../../icons/QrCodeIcon';
import createStyles from './GreetingTextStyles';
import CloseIcon from '../../icons/CloseIcon';
import MessageIcon from '../../icons/MessageIcon';
import EmailIcon from '../../icons/EmailIcon';
import ModalContent from "../../Organisms/ModalContent/ModalContent";
import QRCode from 'react-native-qrcode-svg';
import { BlurView } from 'expo-blur';
import { Auth } from 'aws-amplify';

export default function GreetingsText({ onCardCreated }) {
    const date = new Date();
    const currentTime = date.getHours();
    const colorScheme = useColorScheme();
  
    const dispatch = useDispatch();
    const isQrModalVisible = useSelector(state => state.showQrModal);
    const isModalVisible = useSelector(state => state.modalAddCardVisible);
    const presignedUrlRef = useRef(null);
  
    useEffect(() => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          const username = user.username;
          presignedUrlRef.current = `https://me-v-1-912dc2a2d5f4.herokuapp.com/${username}`;
        })
        .catch(err => console.log(err));
    }, []);
  
    const findGreet = () => {
      if (currentTime >= 5 && currentTime < 8) {
        return "Ранняя пташка!";
      } else if (currentTime >= 8 && currentTime < 12) {
        return "Доброе утро!";
      } else if (currentTime >= 12 && currentTime < 18) {
        return "Добрый день!";
      } else if (currentTime >= 18 && currentTime < 22) {
        return "Добрый вечер!";
      } else {
        return "Пора спать!";
      }
    };
  
    const toggleQrModal = () => {
      dispatch(setShowQrModal(!isQrModalVisible));
    };
  
    const toggleModal = () => {
      dispatch(setModalAddCardVisible(!isModalVisible));
    };
  
    const shareViaEmail = useCallback((subject, body) => {
      const emailUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      Linking.canOpenURL(emailUrl)
        .then((supported) => {
          if (supported) {
            return Linking.openURL(emailUrl);
          } else {
            console.log('Email is not supported on this device.');
          }
        })
        .catch((error) => console.error('An error occurred', error));
    }, []);
  
    const shareViaSms = useCallback((message) => {
      const smsUrl = `sms:?body=${encodeURIComponent(message)}`;
      Linking.canOpenURL(smsUrl)
        .then((supported) => {
          if (supported) {
            return Linking.openURL(smsUrl);
          } else {
            console.log('SMS is not supported on this device.');
          }
        })
        .catch((error) => console.error('An error occurred', error));
    }, []);
  
    const styles = createStyles(colorScheme);
  
    return (
      <View style={styles.container}>
        <Text style={styles.greeting}>{findGreet()}</Text>
  
        <TouchableOpacity onPress={toggleQrModal} style={styles.qrButton} activeOpacity={0.567}>
          <QrCodeIcon width={24} height={24} color="rgba(70, 61, 103, 0.7)" />
        </TouchableOpacity>
  
        <TouchableOpacity onPress={toggleModal} style={styles.plusButton} activeOpacity={0.567}>
          <PlusIcon width={24} height={24} color="rgba(70, 61, 103, 0.7)" />
        </TouchableOpacity>
  
        <Modal
          visible={isQrModalVisible}
          animationType="fade"
          transparent={true}
          onRequestClose={toggleQrModal}
        >
          <BlurView intensity={90} tint={colorScheme === 'dark' ? 'dark' : 'light'} style={styles.blurView}>
            <ScrollView showsVerticalScrollIndicator={false} style={styles.scrollView}>
              <View style={styles.qrCodeViewPosition}>
                <Text style={styles.qrCodeTitle}>Отсканируйте код</Text>
                <View style={styles.openedCardQr}>
                  <View style={styles.qrCode}>
                    <QRCode
                      backgroundColor={colorScheme === 'dark' ? 'rgba(233, 222, 255, .56)' : 'white'}
                      value={presignedUrlRef.current}
                      size={200}
                    />
                  </View>
                  <Text style={styles.paragraphText}>или поделиться с помощью</Text>
                  <View style={styles.buttonsRowQr}>
                    <TouchableOpacity activeOpacity={0.567} style={styles.buttonNew} onPress={toggleQrModal}>
                      <CloseIcon width={48} height={48} color="rgba(70, 61, 103, 0.17)" />
                    </TouchableOpacity>
                    <TouchableOpacity activeOpacity={0.567} style={styles.buttonNew} onPress={() => shareViaSms(presignedUrlRef.current)}>
                      <MessageIcon width={48} height={48} color={colorScheme === 'dark' ? '#519951' : '#519951'} />
                    </TouchableOpacity>
                    <TouchableOpacity activeOpacity={0.567} style={styles.buttonNew} onPress={() => shareViaEmail(presignedUrlRef.current)}>
                      <EmailIcon width={48} height={48} color={colorScheme === 'dark' ? 'rgb(88, 178, 255)' : 'rgb(88, 178, 255)'} />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </ScrollView>
          </BlurView>
        </Modal>
  
        <Modal
          animationType="slide"
          transparent={true}
          visible={isModalVisible}
          onRequestClose={toggleModal}
        >
          <ModalContent onClose={toggleModal} onCardCreated={onCardCreated} />
        </Modal>
      </View>
    );
  }